<template>
  <div class="driver-management" ref="driverManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddDriver"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="importExcel"
        v-if="$store.state.menu.nowMenuList.indexOf('导入') >= 0"
        >导入</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="84px"
      >
        <el-form-item label="姓名：">
          <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-input v-model="form.cph" placeholder="请输入车牌号"></el-input>
        </el-form-item>
        <el-form-item label="所属企业：">
          <companySelect
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="驾驶证类型：">
          <el-select clearable v-model.trim="form.drivingType">
            <el-option
              v-for="item in drivingTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select clearable v-model.trim="form.status">
            <el-option
              v-for="item in statusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证：">
          <el-input v-model.trim="form.idCard" placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      :height="tableHeight"
      :row-class-name="rowColor"
    >
      <el-table-column type="index" label="序号"></el-table-column>

      <el-table-column
        prop="name"
        label="姓名"
        width="150"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        :show-overflow-tooltip="true"
        width="170"
      ></el-table-column>
      <el-table-column prop="drivingType" label="驾驶证类型">
        <template slot-scope="scope">{{
          drivingTypeObj[Number(scope.row.drivingType)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="workNum"
        label="工号"
        width="170"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="idCard"
        label="身份证号码"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="credentialsEndDate"
        label="从业资格证到期日期"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">{{
          statusObj[Number(scope.row.status)]
        }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateDriver(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="
              $store.state.menu.nowMenuList.indexOf('编辑') >= 0 &&
              scope.row.status === '0'
            "
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === '0' ? 'danger' : 'primary'"
            @click="onDisableUser(scope.row)"
            v-if="
              scope.row.status === '0'
                ? $store.state.menu.nowMenuList.indexOf('禁用') >= 0
                : $store.state.menu.nowMenuList.indexOf('启用') >= 0
            "
            >{{ scope.row.status === '0' ? '禁用' : '启用' }}</el-button
          >
          <el-button
              @click="logVehicle(scope.row)"
              type="text"
              size="small"
              sort="primary"
              style="color: #007aff"
              v-if="$store.state.menu.nowMenuList.indexOf('日志') >= 0"
            >日志</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      v-if="dialogVisible"
      width="70%"
    >
      <div>
        <div class="body">
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="驾驶员基本信息" name="first">
              <add-driver
                ref="addDriver"
                :companyList="companyList"
                :sexList="sexList"
                :isIDCardEdit="isIDCardEdit"
                :starLevelList="starLevelList"
                :drivingTypeList="drivingTypeList"
                :drivingChoiceList="drivingChoiceList"
                :rowData="rowData"
                :title="title"
                @upDateData="upDateData"
                @uploading="uploading"
              />
            </el-tab-pane>
            <el-tab-pane label="资格审核" name="second" v-if="!isAddShow">
              <div v-if="qualificationForm.n_WorkerWriteDate">
                <p class="form-title form-title3">
                  <span>岗前培训信息</span>
                </p>
                <el-form
                  :model="qualificationForm"
                  :inline="true"
                  ref="qualificationForm"
                  label-width="110px"
                  label-position="left"
                  class="demo-form-inline dialog-form dialog-form-inline"
                >
                  <el-form-item label="培训时间：">
                    <el-date-picker
                      v-model="qualificationForm.n_WorkerWriteDate"
                      value-format="yyyy-MM-dd"
                      type="date"
                      disabled
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="考试成绩：">
                    <el-input
                      v-model="qualificationForm.n_WorkerWriteResult"
                      disabled
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="负责人：" prop="drivingType">
                    <el-input
                      v-model="qualificationForm.n_WriteResultName"
                      disabled
                    ></el-input>
                  </el-form-item>
                </el-form>
                <p class="form-title form-title3">
                  <span>驾驶员聘用记录</span>
                </p>
                <div class="worker-time">
                  <p class="time-p form-title">聘用时间：</p>
                  <el-date-picker
                    v-model="qualificationForm.n_WorkerEmployStartDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    disabled
                    placeholder="选择日期"
                  ></el-date-picker
                  >至
                  <el-date-picker
                    v-model="qualificationForm.n_WorkerEmployEndDate"
                    value-format="yyyy-MM-dd"
                    type="date"
                    disabled
                    placeholder="选择日期"
                  ></el-date-picker>
                </div>
                <div class="worker-time">
                  <p class="time-p form-title">聘用记录：</p>
                  <p>{{ qualificationForm.n_WorkerEmployRecord }}</p>
                </div>
              </div>
              <div v-else style="line-height: 300px; text-align: center">
                暂无审核信息
              </div>
            </el-tab-pane>
            <el-tab-pane label="奖惩培训记录" name="third" v-if="!isAddShow">
              <p class="form-title form-title3">
                <span>定期安全培训考核记录</span>
              </p>
              <div class="worker-time">
                <p class="time-p form-title">聘用记录：</p>
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="train.assessment"
                >
                </el-input>
              </div>

              <p class="form-title form-title3">
                <span>违规违章记录</span>
              </p>
              <div class="worker-time">
                <p class="time-p form-title">聘用记录：</p>
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="train.violation"
                >
                </el-input>
              </div>
              <p class="form-title form-title3">
                <span>内部奖惩记录</span>
              </p>
              <div class="worker-time">
                <p class="time-p form-title">聘用记录：</p>
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="train.excitation"
                >
                </el-input>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="activeName === 'first'"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-if="activeName === 'third'"
          :loading="editLoading"
          @click="onSure"
          >确认</el-button
        >
      </span>
    </el-dialog>

    <!-- 导入 -->
    <el-dialog title="导入" :visible.sync="downVisible" width="30%">
      <uploadFile
        @uploadSuccess="uploadSuccess"
        url="base/driver/importDriver"
        class="import"
        :type="1"
      ></uploadFile>
    </el-dialog>
    <!-- 日志记录 -->
    <el-dialog
      title="变更记录"
      v-if="dialogVisibleLog"
      :visible.sync="dialogVisibleLog"
      width="60%"
      custom-class="add-vehicle-dialog"
    >
      <logRecord :rowData="rowData" identifyCode="112"></logRecord>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisibleLog = false">取消</el-button>
        <el-button type="primary" size="small" @click="dialogVisibleLog = false">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDriverList,  
  getBusinessCompanyTree,
  updateDriverStatus,
  queryDictsByCodes,
  insertDriverOtherEdit,
  getDriverOtherDetails
} from "@/api/lib/api.js";
import {
  getCurent,
  formatDate
} from "@/common/utils/index.js";

import { formatDict } from "@/common/utils/index";
import uploadFile from "@/components/uploadFile/uploadFile.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import queryQualification from "@/api/lib/qualificationApi.js";
import AddDriver from './components/addDriver.vue';
import logRecord from '../vehicleManagement/components/logRecord.vue';
export default {
  name: "driverManagement",
  components: {
    uploadFile,
    companySelect,
    AddDriver,
    logRecord
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,
      form: {
        name: null,
        cph: "",
        companyId: null,
        drivingType: null,
        status: null,
        currentPage: 1,
        pageSize: 10,
        idCard:"",
      },
      total: 0,
      dialogVisible: false,
      title: "",
      companyList: [],
      sexList: [],
      statusList: [],
      starLevelList: [],
      drivingChoiceList: [],
      drivingTypeList: [],
      drivingTypeObj: {},
      statusObj: {},
      terminalData: [
        {
          deviceNo: "",
          deviceType: "",
          SIMNo: ""
        }
      ],
      isIDCardEdit: false,
      editLoading: false,
      exportForm: {},
      downLoadStatus: false,
      downVisible: false,
      choosedCph: "", //主驾副驾 回显车辆
      isAddShow: true,
      activeName: "first",
      qualificationForm: {}, //资格审核岗前培训信息
      train: {
        assessment: "", //定期安全培训考核记录聘用记录
        violation: "", //违规违章记录聘用记录
        excitation: "" //内部奖惩记录记录聘用记录
      },
      credentialsNoActive: "",
      rowData: null,
      dialogVisibleLog:false,//日志
    };
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.driverManagement.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16;
    },
    //获取资格审核信息
    getqueryQualification (idCard) {
      queryQualification({ n_IDCardNo: idCard })
        .then(res => {
          // queryQualification({n_IDCardNo:'510824196607060010'}).then(res=>{

          this.qualificationForm = res.data[0] || {};
        })
        .catch(err => {
          console.log(err);
        });
    },
    //导出
    exportData () {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "base",
        userId:
          sessionStorage.getItem("userId") || localStorage.getItem("userId"),
        createTime: formatDate(new Date()),
        fileName: `驾驶员信息-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/driver/driverExport"
      };
      addExcelExport(data)
        .then(res => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    onSearch () {
      this.form.currentPage = 1;
      this.getTableData();
    },
    // 获取表格数据
    getTableData () {
      getDriverList(this.form).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.exportForm = { ...this.form };
      });
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getTableData();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.onSearch();
    },
    logVehicle(row){
      this.rowData = { ...row };
      this.dialogVisibleLog = true;
    },
    // 点击表格查看按钮
    onUpdateDriver (row) {
      this.title = "修改驾驶员信息";
      this.credentialsNoActive = row.credentialsNo;
      this.rowData = {
        id: row.id,
        companyName: row.companyName
      }
      this.getqueryQualification(row.idCard); //获取审核信息
      this.querygetDriverOtherDetails(row.credentialsNo); //获取奖惩培训记录详情
      this.isIDCardEdit = true;
      this.dialogVisible = true;
      this.isAddShow = false;
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.dialogVisible = false;
      this.isAddShow = true;
      this.$refs.addDriver.onClose();
      // 新增tab页数据清空
      this.qualificationForm = {};
      this.activeName = "first";
      this.credentialsNoActive = "";
      this.train = {
        assessment: "", //定期安全培训考核记录聘用记录
        violation: "", //违规违章记录聘用记录
        excitation: "" //内部奖惩记录记录聘用记录
      };
      this.rowData = null;
    },
    // 新增驾驶员
    onAddDriver () {
      this.title = "新增驾驶员";
      this.isIDCardEdit = false;
      this.dialogVisible = true;
      this.isAddShow = true;
    },
    //  禁用|启用 驾驶员
    onDisableUser (row) {
      if (row.status === "0") {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h("span", null, "确定禁用驾驶员 "),
            h("span", { style: "color: #059370" }, row.name),
            h("span", null, " ？")
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(() => {
            updateDriverStatus({ id: row.id, status: 1 }).then(res => {
              if (res.code === 1000) {
                this.$message.success("禁用成功");
                row.status = "1";
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消禁用"
            });
          });
      } else {
        updateDriverStatus({ id: row.id, status: 0 }).then(res => {
          if (res.code === 1000) {
            this.$message.success("启用成功");
            row.status = "0";
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    upDateData (val) {
      this.onDialogClose();
      if(val===1){        
        this.onSearch();
      }else if(val===2){
        this.getTableData();
      }
    },
    uploading(flag){
      this.editLoading = flag;
    },
    // 新增/修改驾驶员
    onSave () {
      this.$refs.addDriver.onSave();
    },
    //修改奖惩培训记录
    onSure () {
      const data = {
        credentialsNo: this.credentialsNoActive,
        train: this.train
      };
      this.editLoading = true;
      insertDriverOtherEdit(data)
        .then(res => {
          this.$message.success(res.msg);
          if (res.code === 1000) {
            this.editLoading = false;
            this.querygetDriverOtherDetails(this.credentialsNoActive);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获取奖惩培训记录详情
    querygetDriverOtherDetails (data) {
      getDriverOtherDetails(data)
        .then(res => {
          if (res.code === 1000 && res.data) {
            this.train = res.data.train;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 限制只能输入数字
    onOnlyNumber (row, str) {
      const reg = /^[1-9]\d*$/;
      if (!new RegExp(reg).test(row[str])) {
        row[str] = "";
      }
    },
    // 添加设备
    onAddTerminal () {
      this.terminalData.push({
        deviceNo: "",
        deviceType: "",
        SIMNo: ""
      });
    },
    // 删除设备
    onDeleteTerminal (i) {
      this.terminalData.splice(i, 1);
    },

    getGroupId (v) {
      this.form.companyId = v;
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then(res => {
        if (res.code === 1000) {
          this.companyList = res.data;
        }
      });
    },

    //导入
    importExcel () {
      this.downVisible = true;
    },
    //导入成功
    uploadSuccess () {
      this.downVisible = false;
      this.onSearch();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "ZT,JSZLX,XB,XJ,JSYLX" }).then(res => {
        if (res.code === 1000) {
          this.statusList = res.data.ZT;
          this.drivingTypeList = res.data.JSZLX;
          this.sexList = res.data.XB;
          this.starLevelList = res.data.XJ;
          this.drivingChoiceList = res.data.JSYLX;
          this.statusObj = formatDict(this.statusList);
          this.drivingTypeObj = formatDict(this.drivingTypeList);
        }
      });
    },
    //指定行修改文字颜色
    rowColor ({ row, rowIndex }) {
      if (row.expiring === 1) {
        return 'redColor'
      }
      return ''
    },

  },
  created () {
    this.getCompanyList();
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.onSearch();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },

  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  }
};
</script>

<style lang="scss" scoped>
.driver-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title3 {
      margin: 10px 0 10px;
    }
  }
}
.worker-time {
  display: flex;
  padding: 1.5vh;
  align-items: center;
  .time-p {
    width: 110px;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 200px;
  }
  .el-textarea {
    width: 520px;
  }
}
.add {
  height: 100%;
  padding: 2vh 3vh;
}

// .theme-project-resourse {
.driver-management {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
// }
/deep/.el-table .redColor td {
  color: #ff1212;
}
</style>
