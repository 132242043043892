import axios from 'axios'
import {
    Message
} from 'element-ui'
/** **** 创建axios实例 ******/
/**查看资格审核信息 */
const service = axios.create({
        // baseURL: 'http://pro.datahub.provide.inheritech.top/driver-training', // api的base_url-new
        baseURL: 'https://pro.datahub.provide.inheritech.top/driver-training', // api的base_url-new
        timeout: 60000 // 请求超时时间
    })
    /** **** request拦截器==>对请求参数做处理 ******/
service.interceptors.request.use(
        config => {
            config.headers = {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'APPCODE 7b8342646ca34ec9bfaf318f58e1b8e9'
            }
            config.method === 'post' || config.method === 'put' ?
                (config.data = JSON.stringify({
                    ...config.data
                })) :
                (config.params = {
                    ...config.data
                })
            return config
        },
        error => {
            // 请求错误处理
            console.log(error, 'error')
            Message.error({
                message: '服务器开小差啦~'
            })
        }
    )
    /** **** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
    response => {
        // 成功请求到数据
        if (response.data.code === 1103) {
            Message.error({
                message: response.data.msg
            })
            setTimeout(() => {
                sessionStorage.removeItem('token')
                location.reload()
            }, 1500)
        } else {
            // 这里根据后端提供的数据进行对应的处理
            return Promise.resolve(response.data)
        }
    },
    error => {
        console.log('error', error)
        Message.error({
            message: error.response.data.msg
        })
        return Promise.reject(error)
    }
)
const queryQualification = data => {
    return service({
        method: 'get',
        data
    })
}
export default queryQualification