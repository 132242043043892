var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "driverManagement", staticClass: "driver-management" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _vm.$store.state.menu.nowMenuList.indexOf("新增") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onAddDriver }
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          _vm.$store.state.menu.nowMenuList.indexOf("导入") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.importExcel }
                },
                [_vm._v("导入")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "84px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入车牌号" },
                    model: {
                      value: _vm.form.cph,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cph", $$v)
                      },
                      expression: "form.cph"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属企业：" } },
                [
                  _c("companySelect", {
                    attrs: {
                      clearable: true,
                      companyTree: _vm.companyList,
                      valueName: _vm.valueName,
                      value: _vm.value
                    },
                    on: { getValue: _vm.getGroupId }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "驾驶证类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.drivingType,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "drivingType",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.drivingType"
                      }
                    },
                    _vm._l(_vm.drivingTypeList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "status",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.status"
                      }
                    },
                    _vm._l(_vm.statusList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份证：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入身份证号码" },
                    model: {
                      value: _vm.form.idCard,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "idCard",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.idCard"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSearch }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.tableData.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight,
            "row-class-name": _vm.rowColor
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "姓名",
              width: "150",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属企业",
              "show-overflow-tooltip": true,
              width: "170"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "drivingType", label: "驾驶证类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm.drivingTypeObj[Number(scope.row.drivingType)])
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "workNum",
              label: "工号",
              width: "170",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "idCard",
              label: "身份证号码",
              width: "180",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "credentialsEndDate",
              label: "从业资格证到期日期",
              width: "180",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm.statusObj[Number(scope.row.status)]))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$store.state.menu.nowMenuList.indexOf("编辑") >= 0 &&
                    scope.row.status === "0"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onUpdateDriver(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    (scope.row.status === "0"
                    ? _vm.$store.state.menu.nowMenuList.indexOf("禁用") >= 0
                    : _vm.$store.state.menu.nowMenuList.indexOf("启用") >= 0)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort:
                                scope.row.status === "0" ? "danger" : "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onDisableUser(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status === "0" ? "禁用" : "启用")
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.$store.state.menu.nowMenuList.indexOf("日志") >= 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#007aff" },
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.logVehicle(scope.row)
                              }
                            }
                          },
                          [_vm._v("日志")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "70%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.onDialogClose
              }
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "body" },
                  [
                    _c(
                      "el-tabs",
                      {
                        attrs: { type: "border-card" },
                        model: {
                          value: _vm.activeName,
                          callback: function($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName"
                        }
                      },
                      [
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "驾驶员基本信息", name: "first" } },
                          [
                            _c("add-driver", {
                              ref: "addDriver",
                              attrs: {
                                companyList: _vm.companyList,
                                sexList: _vm.sexList,
                                isIDCardEdit: _vm.isIDCardEdit,
                                starLevelList: _vm.starLevelList,
                                drivingTypeList: _vm.drivingTypeList,
                                drivingChoiceList: _vm.drivingChoiceList,
                                rowData: _vm.rowData,
                                title: _vm.title
                              },
                              on: {
                                upDateData: _vm.upDateData,
                                uploading: _vm.uploading
                              }
                            })
                          ],
                          1
                        ),
                        !_vm.isAddShow
                          ? _c(
                              "el-tab-pane",
                              { attrs: { label: "资格审核", name: "second" } },
                              [
                                _vm.qualificationForm.n_WorkerWriteDate
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "form-title form-title3"
                                          },
                                          [_c("span", [_vm._v("岗前培训信息")])]
                                        ),
                                        _c(
                                          "el-form",
                                          {
                                            ref: "qualificationForm",
                                            staticClass:
                                              "demo-form-inline dialog-form dialog-form-inline",
                                            attrs: {
                                              model: _vm.qualificationForm,
                                              inline: true,
                                              "label-width": "110px",
                                              "label-position": "left"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "培训时间：" }
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  attrs: {
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                    type: "date",
                                                    disabled: "",
                                                    placeholder: "选择日期"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.qualificationForm
                                                        .n_WorkerWriteDate,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.qualificationForm,
                                                        "n_WorkerWriteDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "qualificationForm.n_WorkerWriteDate"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "考试成绩：" }
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.qualificationForm
                                                        .n_WorkerWriteResult,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.qualificationForm,
                                                        "n_WorkerWriteResult",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "qualificationForm.n_WorkerWriteResult"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "负责人：",
                                                  prop: "drivingType"
                                                }
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.qualificationForm
                                                        .n_WriteResultName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.qualificationForm,
                                                        "n_WriteResultName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "qualificationForm.n_WriteResultName"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "form-title form-title3"
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("驾驶员聘用记录")
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "worker-time" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "time-p form-title"
                                              },
                                              [_vm._v("聘用时间：")]
                                            ),
                                            _c("el-date-picker", {
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                disabled: "",
                                                placeholder: "选择日期"
                                              },
                                              model: {
                                                value:
                                                  _vm.qualificationForm
                                                    .n_WorkerEmployStartDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.qualificationForm,
                                                    "n_WorkerEmployStartDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "qualificationForm.n_WorkerEmployStartDate"
                                              }
                                            }),
                                            _vm._v("至 "),
                                            _c("el-date-picker", {
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                disabled: "",
                                                placeholder: "选择日期"
                                              },
                                              model: {
                                                value:
                                                  _vm.qualificationForm
                                                    .n_WorkerEmployEndDate,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.qualificationForm,
                                                    "n_WorkerEmployEndDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "qualificationForm.n_WorkerEmployEndDate"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "worker-time" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "time-p form-title"
                                              },
                                              [_vm._v("聘用记录：")]
                                            ),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.qualificationForm
                                                    .n_WorkerEmployRecord
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "line-height": "300px",
                                          "text-align": "center"
                                        }
                                      },
                                      [_vm._v(" 暂无审核信息 ")]
                                    )
                              ]
                            )
                          : _vm._e(),
                        !_vm.isAddShow
                          ? _c(
                              "el-tab-pane",
                              {
                                attrs: { label: "奖惩培训记录", name: "third" }
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "form-title form-title3" },
                                  [_c("span", [_vm._v("定期安全培训考核记录")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "worker-time" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "time-p form-title" },
                                      [_vm._v("聘用记录：")]
                                    ),
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 4,
                                        placeholder: "请输入内容"
                                      },
                                      model: {
                                        value: _vm.train.assessment,
                                        callback: function($$v) {
                                          _vm.$set(_vm.train, "assessment", $$v)
                                        },
                                        expression: "train.assessment"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  { staticClass: "form-title form-title3" },
                                  [_c("span", [_vm._v("违规违章记录")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "worker-time" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "time-p form-title" },
                                      [_vm._v("聘用记录：")]
                                    ),
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 4,
                                        placeholder: "请输入内容"
                                      },
                                      model: {
                                        value: _vm.train.violation,
                                        callback: function($$v) {
                                          _vm.$set(_vm.train, "violation", $$v)
                                        },
                                        expression: "train.violation"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  { staticClass: "form-title form-title3" },
                                  [_c("span", [_vm._v("内部奖惩记录")])]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "worker-time" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "time-p form-title" },
                                      [_vm._v("聘用记录：")]
                                    ),
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        rows: 4,
                                        placeholder: "请输入内容"
                                      },
                                      model: {
                                        value: _vm.train.excitation,
                                        callback: function($$v) {
                                          _vm.$set(_vm.train, "excitation", $$v)
                                        },
                                        expression: "train.excitation"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.onDialogClose }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.activeName === "first"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            loading: _vm.editLoading
                          },
                          on: { click: _vm.onSave }
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _vm.activeName === "third"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            loading: _vm.editLoading
                          },
                          on: { click: _vm.onSure }
                        },
                        [_vm._v("确认")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "导入", visible: _vm.downVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.downVisible = $event
            }
          }
        },
        [
          _c("uploadFile", {
            staticClass: "import",
            attrs: { url: "base/driver/importDriver", type: 1 },
            on: { uploadSuccess: _vm.uploadSuccess }
          })
        ],
        1
      ),
      _vm.dialogVisibleLog
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "变更记录",
                visible: _vm.dialogVisibleLog,
                width: "60%",
                "custom-class": "add-vehicle-dialog"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisibleLog = $event
                }
              }
            },
            [
              _c("logRecord", {
                attrs: { rowData: _vm.rowData, identifyCode: "112" }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisibleLog = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisibleLog = false
                        }
                      }
                    },
                    [_vm._v("确认")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }